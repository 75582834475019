export default [
  {
    key: 'increasement',
    sortable: false,
    sortField: '',
    label: '#',
    thClass: 'w-50px',
  },
  {
    key: 'name',
    sortable: false,
    sortField: 'name',
    label: 'field.name',
    stickyColumn: true,
    variant: 'light',
    thClass: 'text-left',
    tdClass: 'text-left',
  },
  {
    key: 'username',
    sortable: false,
    sortField: 'username',
    label: 'field.username',
    thClass: 'text-left',
    tdClass: 'text-left',
  },
  {
    key: 'ipaddress',
    sortable: false,
    sortField: '',
    label: 'field.ipAddress',
  },
  {
    key: 'lastActivityAt',
    sortable: false,
    sortField: '',
    label: 'field.lastActiveDate',
  },
  {
    key: 'status',
    sortable: false,
    sortField: '',
    label: 'field.status',
  },
  {
    key: 'createdAt',
    sortable: true,
    sortField: 'createdAt',
    label: 'field.createDate',
    thClass: 'text-left',
    tdClass: 'text-left',
  },
  {
    key: 'createdBy',
    label: 'field.createdBy',
    thClass: 'text-left',
    tdClass: 'text-left',
  },
  {
    key: 'updatedAt',
    sortable: true,
    sortField: 'updatedAt',
    label: 'field.updateDate',
    thClass: 'text-left',
    tdClass: 'text-left',
  },
  {
    key: 'updatedBy',
    label: 'field.updatedBy',
    thClass: 'text-left',
    tdClass: 'text-left',
  },
  {
    key: 'actions',
    label: 'general.action',
  },
];
